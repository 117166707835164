const PLACEMENT_ROLES = {
  "12": {
    "name": "start",
    "color": [0, 255, 0, 64]
  },
  "13": {
    "name": "middle",
    "color": [0, 0, 255, 64]
  },
  "14": {
    "name": "finish",
    "color": [255, 0, 0, 64]
  },
  "15": {
    "name": "foot",
    "color": [255, 255, 0, 64]
  },
}

const DEFAULT_ANGLE = 50

export {
  PLACEMENT_ROLES, DEFAULT_ANGLE
}
