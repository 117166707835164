import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Button, Grid } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { downscale, dataURLToBlob } from '../utils/imageFunctions';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Images({ imageUrls }) {
  return (
    <Box>
      <Grid container spacing={2}>
        {imageUrls.map((imageUrl) => (
          <Grid item xs={12} md={12}>
            {false && <img src={imageUrl} alt="snapshot" style={{ maxWidth: '100vh', maxHeight: '100vh' }} />}
            <TransformWrapper
              initialScale={1}
              centerOnInit
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <div className="tools">
                    <button onClick={() => zoomIn()}>+</button>
                    <button onClick={() => zoomOut()}>-</button>
                    <button onClick={() => resetTransform()}>x</button>
                  </div>
                  <TransformComponent>
                    <img src={imageUrl} alt="snapshot" style={{ maxWidth: '100vw', maxHeight: '100vh' }} />
                    <div>Example text</div>
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
const Example = () => {
  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={200}
      initialPositionY={100}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <React.Fragment>
          <div className="tools">
            <button onClick={() => zoomIn()}>+</button>
            <button onClick={() => zoomOut()}>-</button>
            <button onClick={() => resetTransform()}>x</button>
          </div>
          <TransformComponent>
            <img src="image.jpg" alt="test" />
            <div>Example text</div>
          </TransformComponent>
        </React.Fragment>
      )}
    </TransformWrapper>
  );
};

function UploadTab() {
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // [file, setFile
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  // const handleCapture = ({ target }) => {
  // setImage(URL.createObjectURL(target.files[0]));
  // setSelectedFile(target.files[0]);
  // };

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      downscale(reader.result, 1024).then((resizedImage) => {
        setImage(resizedImage);
        const blob = dataURLToBlob(resizedImage);
        const newFile = new File([blob], file.name, { type: blob.type });
        setSelectedFile(newFile);
      });
    };
    reader.readAsDataURL(file);
  };
  const fetchClimb = async () => {
    if (selectedFile) {
      console.log(selectedFile);
      setLoading(true);
      const formData = new FormData();
      formData.append('image', selectedFile);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/find_climb`, {
          method: 'POST',
          body: formData,
        });
        const json = await response.json();
        const climbId = json.uuid;
        console.info("climbId: ", climbId);
        navigate(`/climb/${climbId}`);
        console.info(json);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {

    fetchClimb();
  }, [selectedFile]);

  return (
    <Box>
      <input
        accept="image/*"
        id="icon-button-photo"
        onChange={handleCapture}
        type="file"
        hidden
      />
      <label htmlFor="icon-button-photo">
        <Button variant="contained" component="span">
          <CameraAltIcon />
        </Button>
      </label>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {loading ? <CircularProgress /> : image && <img src={image} alt="snapshot" style={{ maxWidth: '100vh', maxHeight: '100vh' }} />}
        </Grid>
      </Grid>
    </Box>
  );
}
function CameraTab() {
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // [file, setFile
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleTakePhoto = (dataUri) => {
    const reader = new FileReader();

    downscale(dataUri, 1024).then((resizedImage) => {
      setImage(resizedImage);
      const blob = dataURLToBlob(resizedImage);
      let extension = blob.type.split('/')[1];
      if (extension === 'jpeg') {
        extension = 'jpg';
      }
      const newFile = new File([blob], `camera-image.${extension}`, { type: blob.type });
      setSelectedFile(newFile);
    });

  };
  const fetchClimb = async () => {
    if (selectedFile) {
      console.log(selectedFile);
      setLoading(true);
      const formData = new FormData();
      formData.append('image', selectedFile);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/find_climb`, {
          method: 'POST',
          body: formData,
        });
        const json = await response.json();
        const climbId = json.uuid;
        console.info("climbId: ", climbId);
        navigate(`/climb/${climbId}`);
        console.info(json);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {

    fetchClimb();
  }, [selectedFile]);

  return (
    <Box>
      <Camera
        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {loading ? <CircularProgress /> : image && <img src={image} alt="snapshot" style={{ maxWidth: '100vh', maxHeight: '100vh' }} />}
        </Grid>
      </Grid>
    </Box>
  );
}

function CameraTab2() {
  const [dataUri, setDataUri] = useState('');

  function handleTakePhoto(dataUri) {
    // Do something with the photo. The dataUri is a base64 encoded string of the photo.
    setDataUri(dataUri);
  }

  return (
    <div className="CameraTab">
      <Camera
        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
      />
      {dataUri && (
        <img src={dataUri} alt="captured" />
      )}
    </div>
  );
}

function KeypointTab() {
  const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null); // [file, setFile
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchClimb = async () => {
    if (selectedFile) {
      console.log(selectedFile);
      setLoading(true);
      const formData = new FormData();
      formData.append('image', selectedFile);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/find_climb`, {
          method: 'POST',
          body: formData,
        });
        const json = await response.json();
        const climbId = json.uuid;
        console.info("climbId: ", climbId);
        navigate(`/climb/${climbId}`);
        console.info(json);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      downscale(reader.result, 1024).then((resizedImage) => {
        setImage(resizedImage);
        const blob = dataURLToBlob(resizedImage);
        const newFile = new File([blob], file.name, { type: blob.type });
        fetchKeypoints(newFile);
      });
    };
    reader.readAsDataURL(file);
  }
  const fetchKeypoints = async (file) => {
    if (file) {
      console.log(file);
      setLoading(true);
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/display-keypoints`, {
          method: 'POST',
          body: formData,
        });
        const json = await response.json();
        console.info(json)
        const encodedImages = json;
        const decodedImages = encodedImages.map((encodedImage) => {
          return `data:image/png;base64,${encodedImage}`;
        })
        console.info(decodedImages)
        setImages(decodedImages);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {

    fetchClimb();
  }, [selectedFile]);

  return (
    <Box>
      <input
        accept="image/*"
        id="icon-button-photo"
        onChange={handleCapture}
        type="file"
        capture="environment"
        hidden
      />
      <label htmlFor="icon-button-photo">
        <Button variant="contained" component="span">
          <CameraAltIcon />
        </Button>
      </label>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {loading ? <CircularProgress /> : <Images imageUrls={images} />}
        </Grid>
      </Grid>
    </Box>
  );
}

export default function FindClimb() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Camera" />
          <Tab label="Upload" />
          <Tab label="Keypoint" />
        </Tabs>
      </Box>
      {value === 0 && <CameraTab />}
      {value === 1 && <UploadTab />}
      {value === 2 && <KeypointTab />}
    </Box>
  );
}