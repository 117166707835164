import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Box, Tab, Tabs, Button, Grid, Typography, Select, MenuItem, Link, List, ListItem, ListItemText } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PLACEMENT_ROLES, DEFAULT_ANGLE } from './constants';
import TabbedView from '../components/TabbedView';
import useStickyState from '../hooks/useStickyState';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from 'react-router-dom';
import { floodFill, fillAll } from '../utils/imageFunctions';

export default function SearchClimbs() {
  const [scoredClimbs, setScoredClimbs] = useState([]);
  const canvasRef = useRef(null);
  const inMemoryCanvasRef = useRef(null);
  const imageDataRef = useRef(null);
  const backgroundImageDataRef = useRef(null);
  const backgroundImageCanvasRef = useRef(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(false);
  const [angle, setAngle] = useStickyState(DEFAULT_ANGLE, 'angle');
  const [selectedHoldCenters, setSelectedHoldCenters] = useState([]);
  const [climb, setClimb] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    inMemoryCanvasRef.current = document.createElement('canvas');
  }, []);

  useEffect(() => {
    setIsImageLoading(true);
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const inMemoryCanvas = inMemoryCanvasRef.current;
    const inMemoryContext = inMemoryCanvas.getContext('2d');
    const image = new Image();
    image.src = '/images/kilter-holds.png';
    const backgroundImage = new Image();
    backgroundImage.src = '/images/kilter.jpg';
    image.onload = () => {
      const parent = canvas.parentElement;
      canvas.width = parent.offsetWidth;
      canvas.height = parent.offsetHeight;
      inMemoryCanvas.width = image.width;
      inMemoryCanvas.height = image.height;
      inMemoryContext.drawImage(image, 0, 0, image.width, image.height);


      /* store the original image data in case we need to reset image*/
      imageDataRef.current = inMemoryContext.getImageData(0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
      // set alpha channel in imageDataRef to 0
      const imageData = imageDataRef.current;
      const data = imageData.data;
      let row, col
      const rows = imageData.height;
      const cols = imageData.width;
      for (row = 0; row < rows; row++) {
        for (col = 0; col < cols; col++) {
          let i = (row * cols + col) * 4;
          // data[i + 1] = 255;
          data[i + 3] = 64;
        }
      }
      inMemoryContext.putImageData(imageData, 0, 0);
      context.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height)
      setIsImageLoading(false);
    };

    setIsBackgroundImageLoading(true);
    backgroundImage.onload = () => {
      backgroundImageCanvasRef.current = document.createElement('canvas');
      const backgroundImageCanvas = backgroundImageCanvasRef.current;
      const backgroundImageContext = backgroundImageCanvas.getContext('2d');
      backgroundImageCanvas.width = backgroundImage.width;
      backgroundImageCanvas.height = backgroundImage.height;
      backgroundImageContext.drawImage(backgroundImage, 0, 0, backgroundImage.width, backgroundImage.height);
      setIsBackgroundImageLoading(false);
    }

    const handleClick = (event) => {
      console.info("CALLING HANDLE CLICK")
      let inMemoryCanvas = inMemoryCanvasRef.current;
      let inMemoryContext = inMemoryCanvas.getContext('2d');
      let canvas = canvasRef.current;
      let ctx = canvas.getContext('2d');

      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      console.info('canvas width: ', [canvas.width, canvas.height], [inMemoryCanvas.width, inMemoryCanvas.height])
      const xInMemory = Math.floor(x * inMemoryCanvas.width / canvas.width);
      const yInMemory = Math.floor(y * inMemoryCanvas.height / canvas.height);
      console.info('click xy: ', [x, y], [xInMemory, yInMemory])

      let imageData = inMemoryContext.getImageData(0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
      let fillCenter = floodFill(imageData, xInMemory, yInMemory, [255, 0, 0, 255]);
      console.info('fillCenter: ', fillCenter)
      if (!fillCenter) {
        console.info("fillCenter is null")
      }
      else {
        setSelectedHoldCenters(prevSelectedHoldCenters => {
          if (prevSelectedHoldCenters.indexOf(fillCenter) > -1) {
            return prevSelectedHoldCenters.filter((center) => center !== fillCenter);
          }
          else {
            return [...prevSelectedHoldCenters, fillCenter];
          }
        });
      }
      inMemoryContext.putImageData(imageData, 0, 0);

      ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
    };

    canvas.addEventListener('click', handleClick);

    return () => {
      canvas.removeEventListener('click', handleClick);
    };
  }, []);

  const updateCanvasSize = () => {
    console.info('updateCanvasSize')
    const inMemoryCanvas = inMemoryCanvasRef.current;
    const canvas = canvasRef.current;
    const parent = canvas.parentElement;
    const ctx = canvas.getContext('2d');
    canvas.width = parent.offsetWidth;
    canvas.height = parent.offsetHeight;
    ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
  };

  const drawClimb = () => {
    if (!climb) {
      return;
    }
    const inMemoryCanvas = inMemoryCanvasRef.current;
    const inMemoryContext = inMemoryCanvas.getContext('2d');
    const imageData = inMemoryContext.getImageData(0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
    const { placements, placement_roles } = climb;
    let gridSpacingX = 2 * (106.0 / 4000) * 1020;
    let gridSpacingY = 2 * (82.9 / 3000) * 1024;
    let gridOffsetX = Math.floor((305.0 / 4000.0) * 1024);
    let gridOffsetY = Math.floor((115.0 / 3000) * 1024);

    // let imgHoldLocations = imgOut.slice();
    let height = 1024;
    let scaleX = gridSpacingX / 8.0;
    let scaleY = gridSpacingY / 8;
    let offsetX = gridOffsetX * 0 + 25;
    let offsetY = -90;
    let selectedHolds = [];
    placements.forEach((placement, index) => {
      const { x, y } = placement;
      const yInMemory = Math.floor(height - y * scaleY - offsetY);
      const xInMemory = Math.floor(x * scaleX + offsetX);
      console.info('drawClimb xy: ', [x, y], [xInMemory, yInMemory])

      const color = PLACEMENT_ROLES[placement_roles[index]]?.color || [0, 0, 0];
      console.info("COLOR: ", color)
      floodFill(imageData, xInMemory, yInMemory, color);
    })
    inMemoryContext.putImageData(imageData, 0, 0);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
  }

  const drawSelectedHoldCenters = () => {
    if (!selectedHoldCenters.length) {
      return;
    }
    const inMemoryCanvas = inMemoryCanvasRef.current;
    const inMemoryContext = inMemoryCanvas.getContext('2d');

    selectedHoldCenters.forEach((center) => {
      console.info("drawSelectedHoldCenters center: ", center)
      const [x, y] = center;

      const color = [255, 0, 0];
      console.info("COLOR: ", color)
      // draw circle at x,y
      inMemoryContext.beginPath();
      inMemoryContext.arc(x, y, 25, 0, 2 * Math.PI);
      inMemoryContext.strokeStyle = 'rgba(255, 255, 0, 1)';
      inMemoryContext.lineWidth = 2;
      inMemoryContext.stroke();

    })
    // inMemoryContext.putImageData(imageData, 0, 0);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
  }

  const drawBackgroundImage = () => {
    if (!backgroundImageCanvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const backgroundImageCanvas = backgroundImageCanvasRef.current;
    ctx.drawImage(backgroundImageCanvas, 0, 0, canvas.width, canvas.height);
  }

  useEffect(() => {
    clearImage()
    drawClimb();
    drawSelectedHoldCenters()
  }, [climb, isImageLoading, selectedHoldCenters]);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, []);

  const handleAngleChange = (event) => {
    setAngle(event.target.value);
  }
  const search = async () => {
    console.info("searching")
    if (selectedHoldCenters.length) {
      const data = {
        hold_centers: selectedHoldCenters
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/search_climbs`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const climbs = await response.json();
        console.info("CLIMBS: ", climbs)
        if (climbs?.length) {
          const [score, firstClimb] = climbs[0];
          const climbId = firstClimb.uuid;

          setClimb(firstClimb);
          setScoredClimbs(climbs);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const clearImage = () => {

    const inMemoryCanvas = inMemoryCanvasRef.current;
    const inMemoryContext = inMemoryCanvas.getContext('2d');
    // const imageData = inMemoryContext.getImageData(0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
    const imageData = imageDataRef.current;
    if (!imageData) {
      console.info("no image data")
      return;
    }
    // fillAll(imageData, [0, 0, 0, 0]);
    inMemoryContext.putImageData(imageData, 0, 0);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
  }

  const onChangeClimb = climb => event => {
    // clear current climb from canvas
    console.info("onChangeClimb: ", climb)

    setClimb(climb);
  }

  // console.info("SELECTED HOLD CENTERS: ", selectedHoldCenters)
  return (
    <Grid container sx={{ pl: { xs: '0', md: '0' }, backgroundColor: 'transparent' }}>
      <Grid item xs={12} sx={{ backgroundColor: 'transparent' }}>
        <TabbedView
          tabNames={['Climb', 'Beta']}
          tabs={[
            <Grid key={1} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  {climb?.name} by {climb?.setter_username}
                  {climb?.stats && <Select value={angle} onChange={handleAngleChange}>
                    {climb.stats.map((stat, index) => {
                      return (<MenuItem key={stat.angle} value={stat.angle}>{stat.display_difficulty} @ {stat.angle}</MenuItem>)
                    })}
                  </Select>}
                </Typography>

              </Grid>
              <Grid item xs={4}>
                {climb?.uuid && <Link component={RouterLink} to={`https://kilterboardapp.com/climbs/${climb.uuid}`} target="_blank">Open in the app</Link>}
              </Grid>

            </Grid>,
            <Grid key={2} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  Beta
                </Typography>
              </Grid>
            </Grid>,
          ]}
        />

      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: 'transparent' }}>
        <Box id="canvas-container" sx={{
          position: 'relative',
          width: { xs: '100vw', md: '80%' },
          maxWidth: '70vh',
          margin: 'auto auto',
          '&::before': {
            content: '""',
            display: 'block',
            paddingTop: '100%',
          },
          '& > *': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        }}>
          <Box sx={{
            position: 'absolute',
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            // height: '100vh',
            aspectRatio: '1/1',
            // maxHeight: '70vh',
            // objectFit: 'contain',
            opacity: 1.0
          }}>
            <img
              src="/images/kilter.jpg"
              alt="climb"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'blue'
              }} />
          </Box>
          <Box sx={{
            position: 'absolute',
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            aspectRatio: '1/1',
            // maxHeight: '70vh',
          }}>
            <canvas ref={canvasRef} style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent'
            }} />
          </Box>

        </Box>
      </Grid>
      {false && <Grid item xs={12} sx={{ backgroundColor: 'transparent' }}>
        {selectedHoldCenters.map((center) => {
          return (<div key={`${center[0]},${center[1]}`} style={{
            position: 'absolute',
            left: center[0],
            top: center[1],
            width: 10,
            height: 10,
            backgroundColor: 'red',
            borderRadius: 5,
            zIndex: 1000
          }}></div>)
        })}
      </Grid>}
      <Grid container item xs={12} sx={{ backgroundColor: 'transparent' }}>
        <Grid item sx={{
          backgroundColor: 'transparent',
          width: { xs: '100vw', md: '80%' },
          maxWidth: '70vh',
          margin: 'auto auto',
        }}>
          <Button variant="contained" fullWidth onClick={search}>Search</Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} sx={{ backgroundColor: 'transparent' }}>
        <Grid item sx={{
          backgroundColor: 'transparent',
          width: { xs: '100vw', md: '80%' },
          maxWidth: '70vh',
          margin: 'auto auto',
        }}>
          {loading && <CircularProgress />}
          <List sx={{ maxHeight: '50vh', overflow: 'auto' }}>
            {scoredClimbs.map(([score, climb]) => (
              <ListItem button key={climb.uuid} onClick={onChangeClimb(climb)}>
                <ListItemText primary={climb.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>


    </Grid>

  );
}
