import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Box, Tab, Tabs, Button, Grid, Typography, Select, MenuItem, Link, useMediaQuery } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PLACEMENT_ROLES, DEFAULT_ANGLE } from './constants';
import TabbedView from '../components/TabbedView';
import useStickyState from '../hooks/useStickyState';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from 'react-router-dom';
import { floodFill, fillAll } from '../utils/imageFunctions';


export default function ShowClimb() {
  const { climbId } = useParams();
  const canvasRef = useRef(null);
  const inMemoryCanvasRef = useRef(null);
  const imageDataRef = useRef(null);
  const imageRef = useRef(null);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [angle, setAngle] = useStickyState(DEFAULT_ANGLE, 'angle');
  const isDesktop = useMediaQuery('(min-device-width: 1250px)')

  const fetchClimb = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/get_climb/${climbId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  };
  const { data: climb, isLoading, error } = useQuery(['climb', climbId], fetchClimb);
  console.info("climb: ", climb)

  useEffect(() => {
    inMemoryCanvasRef.current = document.createElement('canvas');
  }, []);

  useEffect(() => {
    setIsImageLoading(true);
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const inMemoryCanvas = inMemoryCanvasRef.current;
    const inMemoryContext = inMemoryCanvas.getContext('2d');


    const image = new Image();
    imageRef.current = image;
    image.src = '/images/kilter-holds.png';
    // image.onload = () => {
    //   canvas.width = image.width;
    //   canvas.height = image.height;
    //   context.drawImage(image, 0, 0, image.width, image.height);
    // };
    image.onload = () => {
      const parent = canvas.parentElement;
      canvas.width = parent.offsetWidth;
      canvas.height = parent.offsetHeight;
      inMemoryCanvas.width = image.width;
      inMemoryCanvas.height = image.height;
      inMemoryContext.drawImage(image, 0, 0, image.width, image.height);
      /* store the original image data in case we need to reset image*/
      imageDataRef.current = inMemoryContext.getImageData(0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
      // set alpha channel in imageDataRef to 0
      const imageData = imageDataRef.current;
      const data = imageData.data;
      let row, col
      const rows = imageData.height;
      const cols = imageData.width;
      for (row = 0; row < rows; row++) {
        for (col = 0; col < cols; col++) {
          let i = (row * cols + col) * 4;
          // data[i + 1] = 255;
          data[i + 3] = 64;
        }
      }
      inMemoryContext.putImageData(imageData, 0, 0);
      context.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height)
      setIsImageLoading(false);
    };

    const handleClick = (event) => {
      let inMemoryCanvas = inMemoryCanvasRef.current;
      let inMemoryContext = inMemoryCanvas.getContext('2d');
      let canvas = canvasRef.current;
      let ctx = canvas.getContext('2d');

      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      console.info('canvas width: ', [canvas.width, canvas.height], [inMemoryCanvas.width, inMemoryCanvas.height])
      const xInMemory = Math.floor(x * inMemoryCanvas.width / canvas.width);
      const yInMemory = Math.floor(y * inMemoryCanvas.height / canvas.height);
      console.info('click xy: ', [x, y], [xInMemory, yInMemory])

      let imageData = inMemoryContext.getImageData(0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
      console.info(floodFill(imageData, xInMemory, yInMemory));
      // floodFill(imageData, 356, 318);
      inMemoryContext.putImageData(imageData, 0, 0);

      ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
    };

    canvas.addEventListener('click', handleClick);

    return () => {
      canvas.removeEventListener('click', handleClick);
    };
  }, []);

  const updateCanvasSize = () => {
    console.info('updateCanvasSize')
    const inMemoryCanvas = inMemoryCanvasRef.current;
    const canvas = canvasRef.current;
    const parent = canvas.parentElement;
    const ctx = canvas.getContext('2d');
    canvas.width = parent.offsetWidth;
    canvas.height = parent.offsetHeight;
    ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
  };

  const drawClimb = () => {
    console.info("drwing climb: ", climb)
    if (!climb) {
      console.info("no climb")
      return;
    }
    const inMemoryCanvas = inMemoryCanvasRef.current;
    const inMemoryContext = inMemoryCanvas.getContext('2d');
    const imageData = inMemoryContext.getImageData(0, 0, inMemoryCanvas.width, inMemoryCanvas.height);
    const { placements, placement_roles } = climb;
    let gridSpacingX = 2 * (106.0 / 4000) * 1020;
    let gridSpacingY = 2 * (82.9 / 3000) * 1024;
    let gridOffsetX = Math.floor((305.0 / 4000.0) * 1024);
    let gridOffsetY = Math.floor((115.0 / 3000) * 1024);

    // let imgHoldLocations = imgOut.slice();
    let height = 1024;
    let scaleX = gridSpacingX / 8.0;
    let scaleY = gridSpacingY / 8;
    let offsetX = gridOffsetX * 0 + 25;
    let offsetY = -90;
    let selectedHolds = [];
    // for (let row of placements) {
    //   let color = [255, 0, 0];

    //   if (y < 0 || y >= height || x < 0 || x >= imgHoldLocations[0].length) {
    //     continue;
    //   }
    //   if (imgHoldLocations[y][x] > 0) {
    //     console.log("Hold found at: ", x, y);
    //     selectedHolds.push({
    //       "x": x,
    //       "y": y,
    //       "placement_id": row[0],
    //       "hole_id": row[1],
    //     });
    //   }
    // }
    placements.forEach((placement, index) => {
      const { x, y } = placement;
      const yInMemory = Math.floor(height - y * scaleY - offsetY);
      const xInMemory = Math.floor(x * scaleX + offsetX);
      console.info('drawClimb xy: ', [x, y], [xInMemory, yInMemory])
      // draw a circle at x,y
      // inMemoryContext.beginPath();
      // inMemoryContext.arc(xInMemory, yInMemory, 5, 0, 2 * Math.PI);
      // inMemoryContext.fillStyle = 'red';
      // inMemoryContext.fill();
      const color = PLACEMENT_ROLES[placement_roles[index]]?.color || [0, 0, 0];
      console.info("COLOR: ", color)
      floodFill(imageData, xInMemory, yInMemory, color);
      // floodFill(imageData, 356, 318);


    })
    inMemoryContext.putImageData(imageData, 0, 0);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(inMemoryCanvas, 0, 0, canvas.width, canvas.height);
    // console.info("drawClimb: ", climb)
  }

  const clearImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  useEffect(() => {
    clearImage();
    drawClimb();
  }, [climb, isImageLoading]);



  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, []);

  const handleAngleChange = (event) => {
    setAngle(event.target.value);
  }

  return (
    <Grid container sx={{ pl: { xs: '0', md: '0' }, backgroundColor: 'transparent' }}>
      <Grid item xs={12} sx={{ backgroundColor: 'transparent' }}>
        <TabbedView
          tabNames={['Climb', 'Beta']}
          tabs={[
            <Grid key={1} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  {climb?.name} by {climb?.setter_username}
                  {climb?.stats && <Select value={angle} onChange={handleAngleChange}>
                    {climb.stats.map((stat, index) => {
                      return (<MenuItem value={stat.angle}>{stat.display_difficulty} @ {stat.angle}</MenuItem>)
                    })}
                  </Select>}
                </Typography>

              </Grid>
              <Grid item xs={4}>
                <Link component={RouterLink} to={`https://kilterboardapp.com/climbs/${climbId}`} target="_blank">Open in the app</Link>
              </Grid>

            </Grid>,
            <Grid key={2} container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  Beta
                </Typography>
              </Grid>
            </Grid>,
          ]}
        />

      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: 'transparent' }}>
        <Box id="canvas-container" sx={{
          position: 'relative',
          width: { sx: '100vw', md: '80%' },
          height: '80vh',
          margin: 'auto auto',
        }}>
          <Box sx={{
            position: 'absolute',
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            // height: '100vh',
            aspectRatio: '1/1',
            maxHeight: '70vh',
            // objectFit: 'contain',
            opacity: 1.0
          }}>
            <img
              src="/images/kilter.jpg"
              alt="climb"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'blue'
              }} />
          </Box>
          <Box sx={{
            position: 'absolute',
            maxWidth: '100%',
            maxHeight: '100%',
            width: '100%',
            // height: '100vh',
            aspectRatio: '1/1',
            maxHeight: '70vh',
            // objectFit: 'contain',
            // opacity: 0.25
          }}>
            <canvas ref={canvasRef} style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent'
            }} />
          </Box>

        </Box>
      </Grid>
    </Grid>

  );
}
