const colorGreaterThan = (color, targetColor = [0, 0, 0]) => {
  return color[0] > targetColor[0] &&
    color[1] > targetColor[1] &&
    color[2] > targetColor[2];
}

const colorIsEqual = (color, targetColor = [0, 0, 0]) => {
  return color[0] === targetColor[0] &&
    color[1] === targetColor[1] &&
    color[2] === targetColor[2];
}

const floodFillHelper = (imageData, pixelStack, fillColor, testFunction, areaCenter = undefined, areaCount = 0) => {
  // console.info("FillingHelper pixelStack: ", pixelStack)
  const rows = imageData.height;
  const cols = imageData.width;
  const data = imageData.data;
  const targetColor = [0, 0, 0, 0];
  const pixel = pixelStack.pop();
  const [col, row] = pixel;
  const pixelIndex = (row * cols + col) * 4;
  const pixelColor = [data[pixelIndex], data[pixelIndex + 1], data[pixelIndex + 2], data[pixelIndex + 3]];
  // console.info("Testing pixel: ", pixel, pixelColor)
  if (colorIsEqual(pixelColor, targetColor)) {
    return [areaCenter, areaCount];
  }
  if (testFunction(pixelColor, targetColor)) {
    // console.info("Filling pixel: ", pixel)
    data[pixelIndex] = fillColor[0];
    data[pixelIndex + 1] = fillColor[1];
    data[pixelIndex + 2] = fillColor[2];
    data[pixelIndex + 3] = fillColor[3];
    if (areaCenter) {
      areaCenter[0] += col;
      areaCenter[1] += row;
    }
    else {
      areaCenter = [col, row];
    }
    areaCount += 1;

    pixelStack.push([col + 1, row]);
    pixelStack.push([col - 1, row]);
    pixelStack.push([col, row + 1]);
    pixelStack.push([col, row - 1]);
  }

  return [areaCenter, areaCount];

}

/* Fills the area connected to (x,y) with fillColor and returns the central point of the area */
const floodFill = (imageData, x, y, fillColor = [255, 0, 0, 128], testFunction = colorGreaterThan) => {
  const pixelStack = [[x, y]];
  let areaCenter;
  let areaCount;
  let res
  while (pixelStack.length) {
    // console.info("Filling pixelStack: ", pixelStack)
    res = floodFillHelper(imageData, pixelStack, fillColor, testFunction, areaCenter, areaCount);
    areaCenter = res[0];
    areaCount = res[1];
  }
  if (areaCenter) {
    areaCenter[0] = Math.floor(areaCenter[0] / areaCount);
    areaCenter[1] = Math.floor(areaCenter[1] / areaCount);
  }
  return areaCenter;
}

const fillAll = (imageData, x, y, fillColor = [255, 0, 0], testFunction = colorGreaterThan) => {
  let row, col
  const rows = imageData.height;
  const cols = imageData.width;
  const data = imageData.data;
  // console.info("Filling all: ", rows, cols)
  for (row = 0; row < rows; row++) {
    for (col = 0; col < cols; col++) {
      let i = (row * cols + col) * 4;
      let r = data[i + 0];
      let g = data[i + 1];
      let b = data[i + 2];
      let a = data[i + 3];
      if (r === 255 && g === 0 && b === 0 && a === 255) {
        // console.info('red')
      }
      data[i] = 255;     // red
      // data[i+1] = data[i+1];   // green
      // data[i+2] = data[i+2];   // blue
      data[i + 3] = data[i + 3] * 0.5; // alpha (transparency)
    }

  }
}

function downscale(image, maxSize) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let { width, height } = img;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
  });
}

function dataURLToBlob(dataURL) {
  const parts = dataURL.split(';base64,');
  const contentType = parts[0].split(":")[1];
  const raw = window.atob(parts[1]);
  const rawLength = raw.length;
  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
}

export {
  floodFill, fillAll, downscale, dataURLToBlob
}