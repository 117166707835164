import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { downscale, dataURLToBlob } from '../../utils/imageFunctions';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

function CameraTab() {
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // [file, setFile
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleTakePhoto = (dataUri) => {
    const reader = new FileReader();

    downscale(dataUri, 1024).then((resizedImage) => {
      setImage(resizedImage);
      const blob = dataURLToBlob(resizedImage);
      let extension = blob.type.split('/')[1];
      if (extension === 'jpeg') {
        extension = 'jpg';
      }
      const newFile = new File([blob], `camera-image.${extension}`, { type: blob.type });
      setSelectedFile(newFile);
    });

  };
  const fetchClimb = async () => {
    if (selectedFile) {
      console.log(selectedFile);
      setLoading(true);
      const formData = new FormData();
      formData.append('image', selectedFile);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL || ''}/api/find_climb`, {
          method: 'POST',
          body: formData,
        });
        const json = await response.json();
        const climbId = json.uuid;
        console.info("climbId: ", climbId);
        navigate(`/climb/${climbId}`);
        console.info(json);

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {

    fetchClimb();
  }, [selectedFile]);

  return (
    <Box>
      <Camera
        onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
        idealFacingMode="environment"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {loading ? <CircularProgress /> : image && <img src={image} alt="snapshot" style={{ maxWidth: '100vh', maxHeight: '100vh' }} />}
        </Grid>
      </Grid>
    </Box>
  )
}

export default CameraTab;
