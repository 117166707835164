import React, { useRef, useEffect } from "react";
import * as d3 from "d3";
window.d3 = d3;
const Graph = ({ nodes, links }) => {
  const ref = useRef();
  const simulation = d3
    .forceSimulation(nodes)
    .force(
      "link",
      d3.forceLink(links).id((d) => d.id)
    )
    .force("charge", d3.forceManyBody())
    .force("center", d3.forceCenter(800 / 2, 600 / 2));

  const zoomed = (event) => {
    console.info(event);
    // const x = event.transform.x + d3.event.sourceEvent.clientX - width / 2;
    // const y = event.transform.y + d3.event.sourceEvent.clientY - height / 2;
    // svg.attr("transform", `translate(${x}, ${y}) scale(${event.transform.k})`);
    svg.attr("transform", event.transform);
  };

  // const zoom = d3.zoom().scaleExtent([0.1, 10]).on("zoom", zoomed);
  const width = 800;
  const height = 600;

  useEffect(() => {
    const svg = d3.select(ref.current);
    const zoom = d3.zoom().on("zoom", (event) => {
      // svg.attr("transform", event.transform);
      const x = event.transform.x + event.sourceEvent.clientX - width / 2;
      const y = event.transform.y + event.sourceEvent.clientY - height / 2;
      svg.attr(
        "transform",
        `translate(${x}, ${y}) scale(${event.transform.k})`
      );
    });
    if (svg.select("g").empty()) {
      svg
        .attr("width", width)
        .attr("height", height)
        .call(zoom)
        // .call(
        //   d3.zoom().on("zoom", (event) => {
        //     svg.attr("transform", event.transform);
        //   })
        // )
        .append("g");
    }

    const weightScale = d3
      .scaleLinear()
      .domain(d3.extent(links, (d) => d.weight))
      .range([1, 10]);

    const colorScale = d3
      .scaleThreshold()
      .domain([100, 500, 1000])
      .range(["red", "orange", "green"]);

    const link = svg
      .select("g")
      .selectAll("line")
      .data(links)
      .join("line")
      .attr("stroke-width", (d) => weightScale(d.weight))
      .attr("stroke", (d) => colorScale(d.weight));

    const node = svg
      .select("g")
      .selectAll("circle")
      .data(nodes)
      .join("circle")
      .attr("r", 5);

    // const label = svg
    //   .select("g")
    //   .selectAll("text")
    //   .data(nodes)
    //   .join("text")
    //   .text((d) => d.id)
    //   .attr("dx", 10)
    //   .attr("dy", ".35em");

    const nodeLabels = svg
      .select("g")
      .selectAll(".node-label")
      .data(nodes)
      .join("text")
      .attr("class", "node-label")
      .text((d) => d.id)
      .attr("dx", 10)
      .attr("dy", ".35em");

    const edgePaths = svg
      .selectAll(".edgepath")
      .data(links)
      .enter()
      .append("path")
      .attr("d", function (d) {
        return (
          "M " +
          d.source.x +
          " " +
          d.source.y +
          " L " +
          d.target.x +
          " " +
          d.target.y
        );
      })
      .attr("class", "edgepath")
      .attr("fill-opacity", 0)
      .attr("stroke-opacity", 0)
      .attr("fill", "blue")
      .attr("stroke", "red")
      .attr("id", function (d, i) {
        return "edgepath" + i;
      });

    const edgeLabels = svg
      .selectAll(".edgelabel")
      .data(links)
      .enter()
      .append("text")
      .style("pointer-events", "none")
      .attr("class", "edgelabel")
      .attr("id", function (d, i) {
        return "edgelabel" + i;
      })
      .attr("dx", 80)
      .attr("dy", 0)
      .attr("font-size", 10)
      .attr("fill", "#aaa");

    edgeLabels
      .append("textPath")
      .attr("xlink:href", function (d, i) {
        return "#edgepath" + i;
      })
      .style("pointer-events", "none")
      .text((d) => `Weight: ${d.weight}`);

    // const edgeLabels = svg
    //   .select("g")
    //   .selectAll(".edge-label")
    //   .data(links)
    //   .join("text")
    //   .attr("class", "edge-label")
    //   .text((d) => `Weight: ${d.weight}`)
    //   .attr("dx", 10)
    //   .attr("dy", ".35em");
    // .style("visibility", "hidden");

    // edgeLabels
    //   .on("mouseover", function () {
    //     console.info("MOUSEOVER", this, d3.select(this));
    //     d3.select(this).style("visibility", "visible");
    //   })
    //   .on("mouseout", function () {
    //     console.info("MOUSEOUT", this, d3.select(this));
    //     d3.select(this).style("visibility", "hidden");
    //   });
    link
      .on("mouseover", function () {
        console.info("MOUSEOVER", this, d3.select(this).select(".edge-label"));
        d3.select(this).select(".edge-label").style("visibility", "visible");
      })
      .on("mouseout", function () {
        d3.select(this).select(".edge-label").style("visibility", "hidden");
      });

    simulation.nodes(nodes);
    // simulation.force("link").links(links);
    simulation.force(
      "link",
      d3
        .forceLink(links)
        .id((d) => d.id)
        .distance(200)
        .strength(0.1)
    );
    simulation.alpha(1).restart();

    simulation.on("tick", () => {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      // label.attr("x", (d) => d.x).attr("y", (d) => d.y);
      nodeLabels.attr("x", (d) => d.x).attr("y", (d) => d.y);

      edgePaths.attr("d", function (d) {
        const path =
          "M " +
          d.source.x +
          " " +
          d.source.y +
          " L " +
          d.target.x +
          " " +
          d.target.y;
        //console.log(d)
        return path;
      });

      // edgeLabels.attr("transform", function (d, i) {
      //   if (d.target.x < d.source.x) {
      //     bbox = this.getBBox();
      //     rx = bbox.x + bbox.width / 2;
      //     ry = bbox.y + bbox.height / 2;
      //     return "rotate(180 " + rx + " " + ry + ")";
      //   } else {
      //     return "rotate(0)";
      //   }
      // });
    });
  }, [nodes, links]);

  return <svg ref={ref}></svg>;
};

export default Graph;
