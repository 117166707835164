import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Button, Grid } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { downscale, dataURLToBlob } from '../utils/imageFunctions';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import CameraTab from '../components/home/CameraTab'
import KeypointTab from '../components/home/KeypointTab'
import UploadTab from '../components/home/UploadTab';

export default function FindClimb() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Camera" />
          <Tab label="Upload" />
          <Tab label="Keypoint" />
        </Tabs>
      </Box>
      {value === 0 && <CameraTab />}
      {value === 1 && <UploadTab />}
      {value === 2 && <KeypointTab />}
    </Box>
  );
}