import * as React from "react";
import MyGraph from "../components/image-registration/Graph";
import { useQuery } from "react-query";

const nodes = [
  { id: 1, name: "Node 1" },
  { id: 2, name: "Node 2" },
  { id: 3, name: "Node 3" },
  { id: 4, name: "Node 4" },
];

const links = [
  { source: 1, target: 2 },
  { source: 1, target: 3 },
  { source: 3, target: 2 },
  { source: 4, target: 1 },
];

async function fetchGraphData() {
  const res = await fetch(
    `${process.env.REACT_APP_API_BASE_URL || ""}/api/image-registration-graph`
  );
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
}

export default function Graph() {
  const { data, status } = useQuery("graphData", fetchGraphData, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "error") {
    return <div>Error fetching data</div>;
  }
  return <MyGraph nodes={data.nodes} links={data.links} />;
}
