import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Button, Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { downscale, dataURLToBlob } from "../../utils/imageFunctions";
import { useDropzone } from "react-dropzone";

function MyDropzone({
  image,
  onDropFile,
  defaultImage = "/images/kilter.jpg",
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    // Accept only images
    accept: "image/*",

    onDrop: (acceptedFiles) => {
      // Do something with the files
      console.log(acceptedFiles);
      onDropFile(acceptedFiles[0]);
    },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        <img
          src={image || defaultImage}
          alt="snapshot"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      }
    </div>
  );
}

function UploadTab() {
  const [image, setImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null); // [file, setFile
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const handleCapture = ({ target }) => {
  // setImage(URL.createObjectURL(target.files[0]));
  // setSelectedFile(target.files[0]);
  // };

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      downscale(reader.result, 1024).then((resizedImage) => {
        setImage(resizedImage);
        const blob = dataURLToBlob(resizedImage);
        const newFile = new File([blob], file.name, { type: blob.type });
        setSelectedFile(newFile);
      });
    };
    reader.readAsDataURL(file);
  };
  const fetchClimb = async () => {
    if (selectedFile) {
      console.log(selectedFile);
      setLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL || ""}/api/find_climb`,
          {
            method: "POST",
            body: formData,
          }
        );
        const json = await response.json();
        const climbId = json.uuid;
        console.info("climbId: ", climbId);
        navigate(`/climb/${climbId}`);
        console.info(json);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchClimb();
  }, [selectedFile]);

  const setFile = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      downscale(reader.result, 1024).then((resizedImage) => {
        setImage(resizedImage);
        const blob = dataURLToBlob(resizedImage);
        const newFile = new File([blob], file.name, { type: blob.type });
        setSelectedFile(newFile);
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <Box>
      <input
        accept="image/*"
        id="icon-button-photo"
        onChange={handleCapture}
        type="file"
        hidden
      />
      <label htmlFor="icon-button-photo">
        <Button variant="contained" component="span">
          <CameraAltIcon />
        </Button>
      </label>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          {loading ? (
            <CircularProgress />
          ) : (
            image && (
              <img
                src={image}
                alt="snapshot"
                style={{ maxWidth: "100vh", maxHeight: "100vh" }}
              />
            )
          )}
        </Grid>
        <Grid item xs={12} md={2}>
          <MyDropzone onDropFile={setFile} image={image} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UploadTab;
